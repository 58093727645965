import {useRouter} from 'next/router';
import {useQuery, type UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';

import type {MilestoneGroup} from '../types';

type ID = string | number;

const queryKey = (id: ID, locale: Locale) => ['milestone-group', id, locale];

const queryFn = (id: ID) => api.get<MilestoneGroup>(`v1/user-milestone-groups/${id}`).then((res) => res.data);

export const useMilestoneGroupQuery = (id: ID, options?: UseQueryOptions<MilestoneGroup>) => {
  const {locale} = useRouter();

  return useQuery<MilestoneGroup>(queryKey(id, locale), () => queryFn(id), {
    ...options,
    enabled: !!id && (options?.enabled ?? true),
  });
};

useMilestoneGroupQuery.queryKey = queryKey;
useMilestoneGroupQuery.queryFn = queryFn;

import {AxiosError} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useInvalidateMe} from '@eksab/hooks';

import {useGetMilestoneQueryData, useInvalidateMilestone, useSetMilestoneGroupsQueryData} from './';

export const useClaimMilestoneMutation = (milestoneID: number, options?: UseMutationOptions<unknown, AxiosError>) => {
  const invalidateMe = useInvalidateMe();
  const invalidateMilestone = useInvalidateMilestone();
  const getMilestoneData = useGetMilestoneQueryData();
  const setMilestoneGroupsData = useSetMilestoneGroupsQueryData();

  return useMutation<unknown, AxiosError>(
    () =>
      api
        .patch('v1/user-milestones/claim', {
          milestone_id: milestoneID,
        })
        .then((res) => res.data),
    {
      ...options,
      onSuccess: (...params) => {
        invalidateMe();
        setMilestoneGroupsData({...getMilestoneData(milestoneID)!, status: 'claimed'});
        invalidateMilestone(milestoneID);

        options?.onSuccess?.(...params);
      },
    },
  );
};

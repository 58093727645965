import {useId} from 'react';
import {IconProps, Icon} from '@chakra-ui/react';

import {milestoneGradientMap} from './const';
import type {MilestoneLevel} from './types';

interface EarnedBadgeShapeProps extends IconProps {
  image: string;
  level?: MilestoneLevel;
}

export function EarnedBadgeShape({image, level = 'expert', ...props}: EarnedBadgeShapeProps) {
  const gradientID = useId();
  const maskID = useId();

  const gradient = milestoneGradientMap[level];

  return (
    <Icon width="123px" height="123px" viewBox="0 0 123 123" fill="none" {...props}>
      <path
        d="M12.2677 24.6999L51.9221 4.52833C57.5268 1.67731 64.146 1.62819 69.7924 4.39574L110.416 24.307C117.97 28.0098 122.4 36.0444 121.498 44.4091L116.285 92.7709C115.437 100.639 110.029 107.267 102.491 109.677L67.0819 120.999C63.0169 122.299 58.6428 122.264 54.5989 120.9L19.9894 109.227C12.585 106.73 7.31328 100.149 6.49212 92.3776L1.44637 44.6278C0.575292 36.3845 4.87948 28.4582 12.2677 24.6999Z"
        fill={`url(#${gradientID})`}
      />

      <path
        d="M56.313 11.3422L14.9007 32.0866C10.7186 34.1816 8.3187 38.6922 8.918 43.3311L15.3157 92.8525C15.8521 97.0051 18.6997 100.493 22.661 101.85L57.7456 113.865C59.9986 114.637 62.4412 114.657 64.7063 113.921L100.384 102.338C104.513 100.998 107.468 97.3539 107.926 93.0369L113.261 42.7846C113.746 38.214 111.338 33.8235 107.223 31.7756L66.1407 11.3294C63.0449 9.78868 59.4048 9.7934 56.313 11.3422Z"
        fill="black"
        stroke="white"
        strokeWidth="2"
      />

      <image href={image} width="100%" height="100%" preserveAspectRatio="xMidYMid slice" mask={`url(#${maskID})`} />

      <path
        d="M55.4201 11.7894L16.1704 31.4506C11.2279 33.9264 8.39169 39.2571 9.09995 44.7395L15.1564 91.6194C15.7904 96.5271 19.1557 100.649 23.8372 102.253L57.1145 113.649C59.7772 114.561 62.6639 114.584 65.3408 113.715L99.15 102.739C104.03 101.155 107.521 96.8484 108.063 91.7465L113.114 44.1669C113.687 38.7652 110.842 33.5765 105.979 31.1563L67.0347 11.7744C63.376 9.95348 59.0741 9.95906 55.4201 11.7894Z"
        fill="none"
        stroke="white"
        strokeWidth="2"
      />

      <mask id={maskID} style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="9" y="11" width="104" height="103">
        <path
          d="M15.3486 32.9807L56.7608 12.2363C59.5716 10.8283 62.8808 10.824 65.6952 12.2247L106.778 32.6709C110.519 34.5326 112.707 38.5239 112.266 42.679L106.932 92.9313C106.515 96.8559 103.829 100.168 100.075 101.387L64.3975 112.97C62.3383 113.639 60.1178 113.621 58.0696 112.919L22.985 100.904C19.3838 99.6703 16.7951 96.4995 16.3074 92.7243L9.90976 43.2029C9.36494 38.9858 11.5467 34.8852 15.3486 32.9807Z"
          fill="white"
        />
      </mask>

      <defs>
        <linearGradient
          id={gradientID}
          x1="5.52608"
          y1="4.22059"
          x2="115.448"
          y2="132.034"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={gradient.color1} />
          <stop offset="0.49" stopColor={gradient.color2} />
          <stop offset="0.94" stopColor={gradient.color3} />
        </linearGradient>
      </defs>
    </Icon>
  );
}

import {Icon, IconProps} from '@chakra-ui/react';

export const StarIcon = (props: IconProps) => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" fill="none" color="green.400" {...props}>
    <path
      d="M5.20979 8.60999L8.02979 7.19999C8.41979 7.00999 8.78979 6.49999 8.86979 6.06999L9.28979 3.51999C9.55979 1.88999 10.7398 1.50999 11.9198 2.67999L13.9098 4.66999C14.2398 4.99999 14.8898 5.18999 15.3598 5.07999L17.8198 4.50999C19.7598 4.05999 20.5198 5.09999 19.4998 6.80999L18.0798 9.19999C17.8198 9.62999 17.8198 10.35 18.0798 10.78L19.4998 13.17C20.5098 14.88 19.7598 15.92 17.8198 15.47L15.3598 14.9C14.8798 14.81 14.2298 15 13.8998 15.33L11.9098 17.32C10.7398 18.49 9.55979 18.11 9.27979 16.48L8.85979 13.93C8.78979 13.5 8.40979 12.99 8.01979 12.8L5.19979 11.39C3.67979 10.62 3.67979 9.37999 5.20979 8.60999Z"
      fill="currentcolor"
    />
    <path
      d="M5.75 16V22C5.75 22.41 5.41 22.75 5 22.75C4.59 22.75 4.25 22.41 4.25 22V16C4.25 15.59 4.59 15.25 5 15.25C5.41 15.25 5.75 15.59 5.75 16Z"
      fill="currentcolor"
    />
    <path
      d="M19.75 19V22C19.75 22.41 19.41 22.75 19 22.75C18.59 22.75 18.25 22.41 18.25 22V19C18.25 18.59 18.59 18.25 19 18.25C19.41 18.25 19.75 18.59 19.75 19Z"
      fill="currentcolor"
    />
    <path
      d="M12.75 21V22C12.75 22.41 12.41 22.75 12 22.75C11.59 22.75 11.25 22.41 11.25 22V21C11.25 20.59 11.59 20.25 12 20.25C12.41 20.25 12.75 20.59 12.75 21Z"
      fill="currentcolor"
    />
  </Icon>
);

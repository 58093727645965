import {IconProps, Icon} from '@chakra-ui/react';

export const CirclesGridIcon = (props: IconProps) => (
  <Icon width="14px" height="14px" viewBox="0 0 14 14" color="black" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 4.08333C1.75 2.79467 2.79467 1.75 4.08333 1.75C5.372 1.75 6.41667 2.79467 6.41667 4.08333C6.41667 5.372 5.372 6.41667 4.08333 6.41667C2.79467 6.41667 1.75 5.372 1.75 4.08333ZM4.08333 2.91667C3.439 2.91667 2.91667 3.439 2.91667 4.08333C2.91667 4.72767 3.439 5.25 4.08333 5.25C4.72767 5.25 5.25 4.72767 5.25 4.08333C5.25 3.439 4.72767 2.91667 4.08333 2.91667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91683 1.75C8.62819 1.75 7.5835 2.79467 7.5835 4.08333C7.5835 5.372 8.62819 6.41667 9.91683 6.41667C11.2055 6.41667 12.2502 5.372 12.2502 4.08333C12.2502 2.79467 11.2055 1.75 9.91683 1.75ZM8.75016 4.08333C8.75016 3.439 9.27248 2.91667 9.91683 2.91667C10.5612 2.91667 11.0835 3.439 11.0835 4.08333C11.0835 4.72767 10.5612 5.25 9.91683 5.25C9.27248 5.25 8.75016 4.72767 8.75016 4.08333Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91683 7.58337C8.62819 7.58337 7.5835 8.62807 7.5835 9.91671C7.5835 11.2053 8.62819 12.25 9.91683 12.25C11.2055 12.25 12.2502 11.2053 12.2502 9.91671C12.2502 8.62807 11.2055 7.58337 9.91683 7.58337ZM8.75016 9.91671C8.75016 9.27236 9.27248 8.75004 9.91683 8.75004C10.5612 8.75004 11.0835 9.27236 11.0835 9.91671C11.0835 10.5611 10.5612 11.0834 9.91683 11.0834C9.27248 11.0834 8.75016 10.5611 8.75016 9.91671Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.08333 7.58337C2.79467 7.58337 1.75 8.62807 1.75 9.91671C1.75 11.2053 2.79467 12.25 4.08333 12.25C5.372 12.25 6.41667 11.2053 6.41667 9.91671C6.41667 8.62807 5.372 7.58337 4.08333 7.58337ZM2.91667 9.91671C2.91667 9.27236 3.439 8.75004 4.08333 8.75004C4.72767 8.75004 5.25 9.27236 5.25 9.91671C5.25 10.5611 4.72767 11.0834 4.08333 11.0834C3.439 11.0834 2.91667 10.5611 2.91667 9.91671Z"
      fill="currentColor"
    />
  </Icon>
);

import {createPortal} from 'react-dom';
import ReactConfetti from 'react-confetti';
import {ButtonProps, Button, keyframes, useToast, useToken} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {useWindowSize} from '@eksab/hooks';
import {isServer} from '@eksab/util';

import {useClaimMilestoneMutation} from '../hooks';
import {GiftIcon} from '../icons';

interface UnlockButtonProps extends ButtonProps {
  milestoneID: number;
}

export const UnlockButton = ({milestoneID, ...props}: UnlockButtonProps) => {
  const toast = useToast();

  const t = useTranslate();
  const claimMilestoneMutation = useClaimMilestoneMutation(milestoneID);

  const onUnlock = () => {
    mixpanel?.track('Button Clicked', {button: 'unlock-milestone', milestoneID});

    claimMilestoneMutation.mutate(undefined, {
      onError: (e) =>
        toast({
          title: e.response?.data?.message ?? t('app.errors.try-again'),
          status: 'error',
        }),
    });
  };

  return (
    <>
      <Button
        className="group"
        gap="6"
        w="60"
        isLoading={claimMilestoneMutation.isLoading}
        onClick={onUnlock}
        {...props}
        isDisabled={props.isDisabled || claimMilestoneMutation.isLoading}
      >
        {t('profile.my-badges.earned.cta.unlock')}

        <GiftIcon _groupHover={{animation: `${shakeAnimation} 1.5s infinite linear`}} />
      </Button>

      <Confetti shouldRun={claimMilestoneMutation.isSuccess} />
    </>
  );
};

const Confetti = ({shouldRun = false}) => {
  const zIndex = useToken('zIndices', 'modal');

  const windowDimensions = useWindowSize();

  if (isServer()) return null;

  if (!shouldRun) return null;

  return createPortal(
    <ReactConfetti
      {...windowDimensions}
      gravity={0.05}
      numberOfPieces={150}
      colors={colors}
      style={{zIndex, position: 'fixed'}}
    />,
    document.body,
  );
};

const shakeAnimation = keyframes`
  25% {
    transform: rotate(-16deg) scale(1.1);
  }

  75% {
    transform: rotate(16deg) scale(1.1);
  }
`;

const colors = [
  '#FB6B02E5',
  '#FB6B02A6',
  '#FB6B02',
  '#FF7272D9',
  '#FB6B0299',
  '#FB6B0273',
  '#FF727273',
  '#FB6B0259',
  '#FF7272E5',
  '#FF7272A6',
  '#FB6B02D9',
  '#FF7272',
  '#FF727259',
  '#FB6B02BF',
  '#FF7272BF',
  '#FF727299',
];

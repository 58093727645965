import {Icon, IconProps} from '@chakra-ui/react';

export function WarningIcon(props: IconProps) {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M26 18C26 19.1046 25.1046 20 24 20C22.8954 20 22 19.1046 22 18C22 16.8954 22.8954 16 24 16C25.1046 16 26 16.8954 26 18Z"
        fill="black"
      />
      <path
        d="M24 23.5C24.8284 23.5 25.5 24.1716 25.5 25V35C25.5 35.8284 24.8284 36.5 24 36.5C23.1716 36.5 22.5 35.8284 22.5 35V25C22.5 24.1716 23.1716 23.5 24 23.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5407 7.98512C26.3569 4.7898 21.6433 4.78978 19.4593 7.98512L18.5955 9.24902C13.1834 17.1674 8.5335 25.5807 4.70804 34.3761L4.5276 34.7909C3.1795 37.8905 5.21622 41.4099 8.57532 41.7853C18.8266 42.9311 29.1735 42.9311 39.4249 41.7853C42.7839 41.4099 44.8207 37.8905 43.4725 34.7909L43.2921 34.3761C39.4667 25.5807 34.8167 17.1675 29.4047 9.24902L28.5407 7.98512ZM21.9361 9.67794C22.9287 8.22552 25.0713 8.22552 26.0641 9.67794L26.9279 10.9418C32.2331 18.7039 36.7911 26.9509 40.5411 35.5725L40.7215 35.9875C41.2643 37.2355 40.4441 38.6527 39.0917 38.8039C29.0619 39.9249 18.9383 39.9249 8.90852 38.8039C7.55594 38.6527 6.73582 37.2355 7.27866 35.9875L7.45908 35.5725C11.209 26.9509 15.7671 18.7039 21.0723 10.9418L21.9361 9.67794Z"
        fill="black"
      />
    </Icon>
  );
}

import {Icon, IconProps} from '@chakra-ui/react';

export function ClockIcon(props: IconProps) {
  return (
    <Icon width="19" height="18" viewBox="0 0 19 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.125 9C3.125 5.47918 5.97918 2.625 9.5 2.625C13.0208 2.625 15.875 5.47918 15.875 9C15.875 12.5208 13.0208 15.375 9.5 15.375C5.97918 15.375 3.125 12.5208 3.125 9ZM10.0625 5.25C10.0625 4.93934 9.81065 4.6875 9.5 4.6875C9.18935 4.6875 8.9375 4.93934 8.9375 5.25V9C8.9375 9.19395 9.0374 9.37417 9.20187 9.477L11.4519 10.8832C11.7153 11.0479 12.0624 10.9678 12.227 10.7044C12.3916 10.4409 12.3116 10.0939 12.0481 9.92925L10.0625 8.68822V5.25Z"
        fill="#2A2E3A"
      />
    </Icon>
  );
}

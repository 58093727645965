import {IconProps, Icon} from '@chakra-ui/react';

export const GiftIcon = (props: IconProps) => (
  <Icon width="24px" height="25px" viewBox="0 0 24 25" fill="none" color="transparent" {...props}>
    <path
      d="M19.6284 7.59036H4.37169C3.46797 7.59036 2.73535 8.32298 2.73535 9.2267C2.73535 10.1304 3.46797 10.863 4.37169 10.863H19.6284C20.5321 10.863 21.2648 10.1304 21.2648 9.2267C21.2648 8.32298 20.5321 7.59036 19.6284 7.59036Z"
      fill="currentcolor"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M19.6531 11.3931L19.0892 18.4727C18.9683 20.1908 17.5585 21.4999 15.8667 21.4999H8.13264C6.44082 21.4999 5.03097 20.1908 4.91013 18.4727L4.34619 11.3931"
      fill="currentcolor"
    />
    <path
      d="M19.6531 11.3931L19.0892 18.4727C18.9683 20.1908 17.5585 21.4999 15.8667 21.4999H8.13264C6.44082 21.4999 5.03097 20.1908 4.91013 18.4727L4.34619 11.3931"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M12 7.59027V21.4991" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M7.97207 7.59085C6.8442 7.59085 5.95801 6.69086 5.95801 5.54542C5.95801 4.39999 6.8442 3.5 7.97207 3.5C9.09995 3.5 9.74445 3.90908 10.4695 5.05452C10.7918 5.46361 12.0002 7.59085 12.0002 7.59085"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.0281 7.59085C17.156 7.59085 18.0422 6.69086 18.0422 5.54542C18.0422 4.39999 17.156 3.5 16.0281 3.5C14.9003 3.5 14.2558 3.90908 13.5307 5.05452C13.2084 5.46361 12 7.59085 12 7.59085"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </Icon>
);

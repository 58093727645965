import type {MilestoneLevel, ShapeGradient} from './types';
import type {Milestone} from '../../types';

export const milestoneGradientMap: Record<MilestoneLevel, ShapeGradient> = {
  beginner: {
    color1: '#5ac57b',
    color2: '#5ac57b',
    color3: '#5ac57b',
  },
  intermediate: {
    color1: '#c15a20',
    color2: '#e38048',
    color3: '#c15a20',
  },
  advanced: {
    color1: '#9b9b9b',
    color2: '#c7c7c7',
    color3: '#9b9b9b',
  },
  expert: {
    color1: '#d79314',
    color2: '#ffe4b0',
    color3: '#d79314',
  },
};

export const milestoneLevelMap: Record<Milestone['level'], MilestoneLevel> = {
  1: 'beginner',
  2: 'intermediate',
  3: 'advanced',
  4: 'expert',
};

import NextLink from 'next/link';
import {Center, CenterProps, Text, keyframes} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';

import {StarIcon} from '../icons';

interface WhatElseButtonProps extends CenterProps {
  milestoneGroupID: number;
}

export const WhatElseButton = ({milestoneGroupID, ...props}: WhatElseButtonProps) => {
  const t = useTranslate();

  return (
    <Center
      className="group"
      as={NextLink}
      href={`/profile/my-badges/earned/groups/${milestoneGroupID}`}
      gap={[1.5, 4]}
      px="5"
      py="3"
      rounded="full"
      borderWidth="1px"
      borderColor="#dae2da"
      bg="#fafafa"
      fontSize={{base: 'sm', lg: 'md'}}
      fontWeight="700"
      color="#6c6c6c"
      transition="border-color 0.3s, color 0.3s"
      _hover={{borderColor: 'green.400'}}
      _active={{color: 'green.400'}}
      {...props}
    >
      <Text color="inherit" noOfLines={1}>
        {t('profile.my-badges.earned.cta.what-else')}
      </Text>

      <StarIcon _groupHover={{animation: `${hoveringAnimation} 1.5s infinite linear`}} />
    </Center>
  );
};

const hoveringAnimation = keyframes`
  25% {
    transform: translateY(-0.125rem);
  }

  75% {
    transform: translateY(0.125rem);
  }
`;

import {useRouter} from 'next/router';
import {useQuery, useQueryClient, type UseQueryOptions} from '@tanstack/react-query';

import {api} from '@eksab/api';

import type {MilestoneDetails} from '../types';

type ID = string | number;

const queryKey = (id: ID, locale: Locale) => ['milestone', `${id}`, locale];

const queryFn = (id: ID) => api.get<MilestoneDetails>(`v1/milestones/${id}/progress`).then((res) => res.data);

export const useMilestoneQuery = (id: ID, options?: UseQueryOptions<MilestoneDetails>) => {
  const {locale} = useRouter();

  return useQuery<MilestoneDetails>(queryKey(id, locale), () => queryFn(id), {
    ...options,
    enabled: !!id && (options?.enabled ?? true),
  });
};

export const useInvalidateMilestone = () => {
  const {locale} = useRouter();
  const queryClient = useQueryClient();

  return (id: ID) => queryClient.invalidateQueries(queryKey(id, locale));
};

export const useGetMilestoneQueryData = () => {
  const {locale} = useRouter();
  const queryClient = useQueryClient();

  return (id: ID) =>
    queryClient.getQueryData<MilestoneDetails>(queryKey(id, locale), {
      exact: false,
    });
};

useMilestoneQuery.queryKey = queryKey;
useMilestoneQuery.queryFn = queryFn;

import {useRouter} from 'next/router';
import {UseQueryOptions, useQuery, useQueryClient} from '@tanstack/react-query';

import {api} from '@eksab/api';
import {useAccessToken} from '@eksab/hooks';

import type {MilestoneDetails, MilestoneGroup} from '../types';

const queryKey = (locale: Locale) => ['milestone-groups', locale];

const queryFn = () => api.get<{data: MilestoneGroup[]}>('v1/user-milestone-groups').then((res) => res.data.data);

export const useMilestoneGroupsQuery = (options?: UseQueryOptions<MilestoneGroup[]>) => {
  const {locale} = useRouter();

  const isLoggedIn = useAccessToken().isSuccess;

  return useQuery<MilestoneGroup[]>(queryKey(locale), queryFn, {
    ...options,
    enabled: isLoggedIn && (options?.enabled ?? true),
  });
};

export function useSetMilestoneGroupsQueryData() {
  const {locale} = useRouter();
  const queryClient = useQueryClient();

  return (updatedMilestone: MilestoneDetails) =>
    queryClient.setQueryData<MilestoneGroup[]>(queryKey(locale), (groups) =>
      groups?.map((group) => {
        if (group.id !== updatedMilestone.group.id) return group;

        return {
          ...group,
          milestones: group.milestones.map((milestone) =>
            milestone.id === updatedMilestone.id ? updatedMilestone : milestone,
          ),
        };
      }),
    );
}

useMilestoneGroupsQuery.queryKey = queryKey;
useMilestoneGroupsQuery.queryFn = queryFn;

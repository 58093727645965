import {Icon, IconProps} from '@chakra-ui/react';

export const LockIcon = (props: IconProps) => (
  <Icon width="63px" height="62px" viewBox="0 0 63 62" fill="none" {...props}>
    <rect x="0.977295" y="0.5" width="60.6364" height="60.6364" rx="30.3182" fill="#D3D3D3" />
    <rect x="0.977295" y="0.5" width="60.6364" height="60.6364" rx="30.3182" stroke="white" />
    <mask
      id="mask0_1315_1167"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="19"
      y="18"
      width="25"
      height="25"
    >
      <path d="M19.2955 18.8182H43.2955V42.8182H19.2955V18.8182Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1315_1167)">
      <path
        d="M31.2955 21.9747C27.8073 21.9747 24.9797 24.8023 24.9797 28.2905V34.6063H27.5061V28.2905C27.5061 27.2854 27.9053 26.3216 28.616 25.6109C29.3266 24.9002 30.2905 24.501 31.2955 24.501C32.3006 24.501 33.2644 24.9002 33.9751 25.6109C34.6858 26.3216 35.085 27.2854 35.085 28.2905V34.6063H37.6113V28.2905C37.6113 24.8023 34.7837 21.9747 31.2955 21.9747Z"
        fill="#7B7B7B"
      />
      <path
        d="M38.8743 39.659C38.8743 40.3291 38.6082 40.9716 38.1344 41.4454C37.6606 41.9192 37.018 42.1854 36.348 42.1854H26.2427C25.5727 42.1854 24.9301 41.9192 24.4564 41.4454C23.9826 40.9716 23.7164 40.3291 23.7164 39.659V33.3433C23.7164 32.6732 23.9826 32.0307 24.4564 31.5569C24.9301 31.0831 25.5727 30.8169 26.2427 30.8169H36.348C37.018 30.8169 37.6606 31.0831 38.1344 31.5569C38.6082 32.0307 38.8743 32.6732 38.8743 33.3433V39.659Z"
        fill="#444444"
      />
    </g>
  </Icon>
);

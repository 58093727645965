import {useQuery, useQueryClient} from '@tanstack/react-query';

import {api} from '@eksab/api';

import type {MyBadge} from '../types';

interface PurchasedBadgesResponse {
  data: MyBadge[];
  meta: {total_count: number};
}

const queryKey = ['purchased-badges'];

const queryFn = () => api.get('v1/user-badges').then((res) => res.data);

export function usePurchasedBadgesQuery() {
  return useQuery<PurchasedBadgesResponse>(queryKey, queryFn);
}

export function useInvalidatePurchasedBadges() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries<PurchasedBadgesResponse>(queryKey, {exact: false});
}

usePurchasedBadgesQuery.queryKey = queryKey;
usePurchasedBadgesQuery.queryFn = queryFn;

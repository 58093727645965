import {useIntl} from 'react-intl';
import {Flex, FlexProps, Text} from '@chakra-ui/react';

import {LockIcon} from './LockIcon';
import {EarnedBadgeShape} from './EarnedBadgeShape';
import {milestoneGradientMap, milestoneLevelMap} from './const';
import type {Milestone} from '../../types';

interface EarnedBadgeProps extends FlexProps {
  milestone: Milestone;
}

export const EarnedBadge = ({milestone, ...props}: EarnedBadgeProps) => {
  const format = useIntl().formatNumber;

  const isClaimed = milestone.status === 'claimed';
  const milestoneLevel = milestoneLevelMap[milestone.level];

  return (
    <Flex
      alignItems="center"
      pos="relative"
      flexDir="column"
      filter={isClaimed ? 'auto' : 'grayscale(1)'}
      transition="filter 0.5s"
      _hover={{filter: 'auto'}}
      _groupHover={{filter: 'auto'}}
      sx={{
        containerType: 'inline-size',
        containerName: 'earned-badge',
        '@container earned-badge (max-width: 8rem)': {
          '.lock-icon': {
            width: '40%',
          },
          '.milestone-target': {
            fontSize: 'sm',
            paddingInline: 1.5,
            paddingBlock: 1.5,
          },
        },
        '@container earned-badge (max-width: 5rem)': {
          '.milestone-target': {
            fontSize: 'xs',
          },
        },
        '@container earned-badge (max-width: 4.125rem)': {
          '.milestone-target': {
            fontSize: 'x-small',
            paddingInline: 1,
            paddingBlock: 0.5,
          },
        },
      }}
      {...props}
    >
      {!isClaimed && <LockIcon className="lock-icon" width="30%" height="auto" pos="absolute" top="0" />}

      <EarnedBadgeShape image={milestone.package.badge.artwork} boxSize="full" level={milestoneLevel} />

      <Flex
        className="milestone-target"
        pos="absolute"
        bottom="-0.5rem"
        px="2.5"
        py="2"
        bg="#2a2e3a"
        rounded="xl"
        fontSize="clamp(0.625rem, 2vw + 0.125rem, 1.125rem)"
        fontWeight="700"
      >
        <Text
          color="white"
          background={
            milestoneLevel === 'expert'
              ? `linear-gradient(139deg, ${milestoneGradientMap.expert.color1} 4%, ${milestoneGradientMap.expert.color2} 51.5%, ${milestoneGradientMap.expert.color3} 95%)`
              : 'white'
          }
          backgroundClip="text"
          sx={{WebkitTextFillColor: 'transparent'}}
        >
          {format(milestone.target)}
        </Text>
      </Flex>
    </Flex>
  );
};
